import React from "react"
import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { string, bool, object, oneOfType, element } from "prop-types"
import FONTS from "../constants/fonts"

const useStyles = makeStyles(theme => ({
  root: {
    textTransform: ({ textTransform }) => textTransform && textTransform,
    fontWeight: `${FONTS.WEIGHT.MEDIUM} !important`,
  },
  header: {
    fontSize: FONTS.SIZE.XXM,
    lineHeight: "90%",
    [theme.breakpoints.down("md")]: {
      fontSize: FONTS.SIZE.L,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: FONTS.SIZE.XM,
    },
  },
  subheader: {
    fontSize: FONTS.SIZE.XM,
    fontWeight: `${FONTS.WEIGHT.MEDIUM} !important`,
    [theme.breakpoints.down("sm")]: {
      fontSize: FONTS.SIZE.XL,
    },
  },
}))

const SubHeader = ({ children, sub, style, className, textTransform }) => {
  const classes = useStyles({ textTransform })

  return (
    <Typography
      style={style}
      className={`${classes.root} ${
        sub ? classes.subheader : classes.header
      } ${className}`}
      variant={sub ? "h3" : "h4"}
    >
      {children}
    </Typography>
  )
}

SubHeader.propTypes = {
  children: oneOfType([string, element]).isRequired,
  sub: bool,
  style: object,
  className: string,
  textTransform: string,
}

SubHeader.defaultProps = {
  sub: false,
  style: {},
  className: "",
}

export default SubHeader
